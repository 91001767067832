<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters class="mt-3">
        <v-col cols="12" md="9" class="pa-5 rounded-lg bg_col1">
          <h2 class="pa-2 txt_white">Report Manajemen Operasional</h2>
          <div>
            <h3 class="px-2 mt-2 txt_white">Event</h3>
            <v-row no-gutters>
              <v-col cols="12" md="8">
                <div class="pa-2">
                  <v-card flat rounded="lg" class="pa-3 d-flex align-center">
                    <div
                      style="width: 30%"
                      class="d-flex align-center justify-center"
                    >
                      <v-progress-circular
                        v-if="!loading && dataSet"
                        class="mr-3"
                        :value="
                          (dataSet.event_tipe_individu /
                            (dataSet.event_tipe_individu +
                              dataSet.event_tipe_project)) *
                          100
                        "
                        color="#0A9794"
                        :size="110"
                        :width="10"
                      >
                      </v-progress-circular>
                      <v-progress-circular
                        v-if="loading"
                        class="mr-3"
                        indeterminate
                        color="primary"
                        :size="110"
                        :width="10"
                      ></v-progress-circular>
                    </div>
                    <div>
                      <div class="ml-4" v-if="!loading && dataSet">
                        <p class="ma-0" style="color: #5f7c78">Total Event</p>
                        <div class="d-flex align-center">
                          <h1 class="txt_color_default">
                            {{
                              dataSet.event_selesai +
                              dataSet.event_belum_selesai
                            }}
                          </h1>
                          <h4>&nbsp;Event</h4>
                        </div>
                      </div>
                      <v-skeleton-loader
                        class="ml-4"
                        width="130px"
                        v-if="loading"
                        type="list-item-three-line"
                      ></v-skeleton-loader>
                      <div class="d-flex align-center">
                        <div v-if="!loading && dataSet" class="mr-2">
                          <div class="d-flex align-center">
                            <div
                              class="rounded-circle mr-2"
                              style="
                                width: 10px;
                                height: 10px;
                                background: #0a9794;
                              "
                            ></div>
                            <p class="ma-0" style="color: #5f7c78">
                              Individu Event
                            </p>
                          </div>
                          <div class="ml-4 d-flex align-center">
                            <h1 class="txt_color_default">
                              {{ dataSet.event_tipe_individu }}
                            </h1>
                            <h4>&nbsp;Event</h4>
                          </div>
                        </div>
                        <div v-if="!loading && dataSet">
                          <div class="d-flex align-center">
                            <div
                              class="rounded-circle mr-2"
                              style="
                                width: 10px;
                                height: 10px;
                                background: #deac2c;
                              "
                            ></div>
                            <p class="ma-0" style="color: #5f7c78">
                              Project Event
                            </p>
                          </div>
                          <div class="ml-4 d-flex align-center">
                            <h1 class="txt_color_default">
                              {{ dataSet.event_tipe_project }}
                            </h1>
                            <h4>&nbsp;Event</h4>
                          </div>
                        </div>
                        <div v-if="loading" class="d-flex align-center">
                          <v-skeleton-loader
                            v-for="(item, n) in 2"
                            :key="n"
                            class="mr-2"
                            width="130px"
                            type="list-item-three-line"
                          ></v-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pa-2">
                  <v-card flat rounded="lg" class="pa-3">
                    <div class="d-flex align-center">
                      <div
                        class="rounded-circle mr-2"
                        style="width: 40px; height: 40px; background: #4adcb9"
                      ></div>
                      <div
                        v-if="!loading && dataSet"
                        class="mr-2"
                        style="flex-grow: 1"
                      >
                        <p class="ma-0" style="color: #5f7c78">Total</p>
                        <h3 class="txt_color_default">Event Selesai</h3>
                      </div>
                      <h1 v-if="!loading && dataSet" class="txt_color_default">
                        {{ dataSet.event_selesai }}
                      </h1>
                      <v-skeleton-loader
                        class="ml-4"
                        style="flex-grow: 1"
                        height="30px"
                        v-if="loading"
                        type="image"
                      ></v-skeleton-loader>
                    </div>
                  </v-card>
                  <v-card flat rounded="lg" class="mt-4 pa-3">
                    <div class="d-flex align-center">
                      <div
                        class="rounded-circle mr-2"
                        style="width: 40px; height: 40px; background: #ffda7c"
                      ></div>
                      <div
                        v-if="!loading && dataSet"
                        class="mr-2"
                        style="flex-grow: 1"
                      >
                        <p class="ma-0" style="color: #5f7c78">Total</p>
                        <h3 class="txt_color_default">Event Berjalan</h3>
                      </div>
                      <h1 v-if="!loading && dataSet" class="txt_color_default">
                        {{ dataSet.event_belum_selesai }}
                      </h1>
                      <v-skeleton-loader
                        class="ml-4"
                        style="flex-grow: 1"
                        height="30px"
                        v-if="loading"
                        type="image"
                      ></v-skeleton-loader>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
            <h3 class="pa-2 txt_white">Kategori Layanan</h3>
            <div
              v-if="!loading && dataSet"
              class="d-flex flex-wrap"
              style="width: 100%"
            >
              <div
                v-for="(item, n) in dataSet.layanan"
                :key="n"
                style="flex-grow: 1; flex-shrink: 0"
              >
                <v-card
                  flat
                  rounded="lg"
                  :class="n != dataSet.layanan.length - 1 ? 'card' : 'card'"
                >
                  <div style="min-height: 60%; width: 100%">
                    <p class="ma-0 text-center" style="color: #5f7c78">
                      {{ item.layanan }}
                    </p>
                  </div>
                  <h1 class="txt_color_default text-center">
                    {{ item.total }}
                  </h1>
                </v-card>
              </div>
            </div>
            <div v-if="loading" class="pa-2">
              <v-skeleton-loader
                height="100px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="pa-2 rounded-lg">
          <div class="pa-2">
            <h3 class="txt_color_default">Detail</h3>
          </div>
          <div v-if="!loading && dataSet" class="pa-2">
            <div>
              <p class="ma-0 small_txt txt_color">Nama Klien</p>
              <h5>{{ dataSet.klien.nama?dataSet.klien.nama:'-' }}</h5>
            </div>
            <div class="mt-3">
              <p class="ma-0 small_txt txt_color">Email</p>
              <h5>{{ dataSet.klien.email?dataSet.klien.email:'-' }}</h5>
            </div>
            <div class="mt-3">
              <p class="ma-0 small_txt txt_color">Alamat</p>
              <h5>
                {{ dataSet.klien.alamat?dataSet.klien.alamat:'-' }}
              </h5>
            </div>
          </div>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <v-divider></v-divider>
          <div v-if="!loading && dataSet" class="pa-2">
            <div>
              <p class="ma-0 small_txt txt_color">Nama Akun</p>
              <h5 class="text-capitalize">{{ dataSet.profile.nama?dataSet.profile.nama:'-' }}</h5>
            </div>
            <div class="mt-3">
              <p class="ma-0 small_txt txt_color">Jenis Kelamin</p>
              <h5>{{ dataSet.profile.jenis_kelamin?dataSet.profile.jenis_kelamin:'-' }}</h5>
            </div>
            <div class="mt-3">
              <p class="ma-0 small_txt txt_color">Jabatan</p>
              <h5>{{ dataSet.profile.jabatan?dataSet.profile.jabatan:'-' }}</h5>
            </div>
            <div class="mt-3">
              <p class="ma-0 small_txt txt_color">Alamat</p>
              <h5>{{ dataSet.profile.alamat?dataSet.profile.alamat:'-'}}</h5>
            </div>
          </div>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import navbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { navbarProfile },
  name: "reportManajemenOperasional",
  data() {
    return {
      loading: false,
      dataSet: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Report Manajemen Operasional",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // get data report manajemen operasional
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/report/manajemen-operasional`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.txt_color {
  color: #a9a9ab !important;
}
.card {
  background: white;
  border-radius: 8px;
  padding: 12px;
}
.bg_col1 {
  background: linear-gradient(50deg, #052633 19.01%, #106967 92.51%);
  border-radius: 12px;
}
.card {
  padding: 12px;
  min-height: 80px;
  margin: 8px 6px;
  flex-grow: 1;
  flex-shrink: 0;
}
>>> .v-progress-circular__underlay {
  stroke: #deac2c !important;
}
</style>
